import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Popup } from "@progress/kendo-react-popup";
import { useTranslation } from "react-i18next";
import { RadioButton, RadioGroup } from "@progress/kendo-react-inputs";
import * as actionTypes from '../../../../store/actions/types';
import {
  getSelectedState,
  Grid,
  GridColumn,
} from "@progress/kendo-react-grid";
import { selectAuditorList } from "../../../../store/reducer/commonReducer";
import {
  getStorePlanList,
  saveStorePlan,
  getLockStatus,
  downloadPlan,
  nrpsSubmitPlan,
  recollectPlan,
} from "../../../../store/actions/workOrder/actionWorkOrder";
import { useSelector, useDispatch } from "react-redux";
import { filterBy, getter, orderBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import columns, { columnMenuConfig } from "./columns";
import columnsBlock, { columnMenuConfigBlock } from "./columnsBlock";
import UploadWindow from "./UploadWindow";
import { RowRender } from "../../../ui/grid/renders";
import LoadMask from "../../../../utils/LoadMask";
import { MessageBox, showToast } from "../../../../utils/Notification";
import recollect from "../../../../assets/images/recollect.png"

import moment from "moment";
import {
  CustomCellDropDown,
  SecondaryResourceIDCell,
  ResourceIDCell,
  ResourceNameCell,
  NonEditableCell,
  DatePickerCell,
  TimeIntervalCell,
  PlainInputCell,
  CollType,
  LimitTime,
  CollectionDays,
  EstimatedEans,
  NoteCollection,
  SecondaryResourceNameCell,
} from "./actionWorkOrderUtils";
import { Tooltip } from "@progress/kendo-react-tooltip";
import ViewMore from "./viewMore";
import UpcBtn from "./upcBtn";
import { isColumnActive } from "../../../grid/CustomColumnMenu";
import UpcWindow from "./upcWindow";
import { updateUserPreferenceAction } from "../../../../store/actions/commonActions";
import { searchUserPrefrence } from "../../../../store/actions/dashboard";
import { setOrderIndex } from "../../../../utils/Utility";
import useLogout, { checkInvalidSession } from "../../../../utils/useLogout";
import { toast } from "react-toastify";
import { MyPager, validateTimeCheck } from "../../../generalHooks";
import Header from "../../../header";

let SAVEDGRIDDATA = [];
const radioData = [
  {
    label: "Plan",
    value: "plan",
  },
  {
    label: "Replan",
    value: "replan",
  },
  {
    label: "Collected",
    value: "collected",
  },
  {
    label: "All",
    value: "all",
  },
];
const GRID_NAME = "WO_STORE_GRID";

const initialColumnsState = setOrderIndex(columns);
const initialBlockIdColumnsState = setOrderIndex(columnsBlock);
const ActionWorkOrder = (props) => {
  const workOrderDetail = useSelector(
    (state) => state.commonReducer.workOrderDetail
  );
  const { t } = useTranslation();
  const { handleAuthContext } = useLogout();
  const history = useHistory();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const EDIT_FIELD = "inEdit";
  const COLLECTION_TYPE = workOrderDetail?.CollectionType;
  const WEEK_NO = workOrderDetail?.week;
  const JOB_ID = workOrderDetail?.JobId;
  const JOB_ACTION =
    workOrderDetail?.JobAction === "Plan / Replan"
      ? "Replan"
      : workOrderDetail?.JobAction;
  const DATA_ITEM_KEY = "customID";
  const authContext = useSelector((state) => state.commonReducer.authContext);
  const [jobAction, setJobAction] = useState(JOB_ACTION?.toLowerCase());

  const createDataState = (data, dataState) => {
    return {
      result: {
        data,
        take: dataState.take,
      },
      dataState: dataState,
    };
  };

  const initialState = createDataState([], {
    take: 20,
    skip: 0,
  });
  const dispatch = useDispatch();
  const serverColumns = useSelector(
    (state) => state.commonReducer.gridUserPrefrence
  );

  const idGetter = getter(DATA_ITEM_KEY);
  const [stateColumns, setStateColumns] = useState(initialColumnsState);
  const [selectedState, setSelectedState] = useState({});
  const [uploadWindow, setUploadWindow] = useState(false);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [result, setResult] = useState(initialState.result);
  const [showLoader, setShowLoader] = useState({
    className: ".workOrders_grid",
    msg: "loading",
  });
  const [fetchAllPlan, setFetchAllPlan] = useState(null);
  const [messageProps, setMessageProps] = useState(null);
  const [nrpsReport, setNrpsReport] = React.useState(false);
  const [upcRedistribution, setUpcRedistribution] = React.useState(false);
  const dependentDataFields = ["AuditorId", "PRIMARY_RESOURCE_NAME"];
  const dependentDataFieldsSecondary = ["SECONDARY_RESOURCE_IDS", "SECONDARY_RESOURCE_NAME"];
  const [upcWindow, setUpcWindow] = useState(null);
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState([
    {
      field: "BatchId",
      dir: "asc",
    },
  ]);
  const [gridData, setGridData] = useState({
    data: [],
    total: 0
  });
  const [fetchedData, setFetchedData] = useState([]);

  const [data, setData] = useState({ data: [], total: 0 });
  const [position, setPosition] = useState({});
  const [dragStartPosition, setDragStartPosition] = useState({});
  const [drag, setDrag] = useState(false);
  const [editEnabled, setEditEnabled] = useState(-1);
  const [selectedRadio, setSelectedRadio] = useState("storeId"); // Default radio selection
  const [hasNonZeroBlockId, setHasNonZeroBlockId] = useState(false);
  const pageSize = 25;

  const screen = 3;

  const userroleDetails = useSelector((state) => state.dashboardReducer.userrolepermssioninfo)
  const [roleWorkOrderPage, setRoleWorkOrderPage] = useState({})
  const [previousgrid,setPreviousGrid]=useState(gridData)


  function getObjectByScreenId(screenId) {
    for (let i = 0; i < userroleDetails?.rolePermissionsInfo.length; i++) {
      if (userroleDetails?.rolePermissionsInfo[i].screensInfo.screenId === screenId) {
        return userroleDetails?.rolePermissionsInfo[i];
      }
    }
    return null; // Return null if not found
  }

  useEffect(() => {
    setRoleWorkOrderPage(getObjectByScreenId(screen))
  }, [])

  console.log(roleWorkOrderPage, screen, "WO3-1234")

  const enterEdit = (dataItem, field, dataIndex = -1) => {
    const newData = gridData?.data.map((item) => ({
      ...item,
      [EDIT_FIELD]: item?.customID === dataItem?.customID ? field : undefined,
    }));
    setGridData((prev) => ({ ...prev, data: newData }));
    setEditEnabled(dataIndex);
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState || initialColumnsState);
  };

  const exitEdit = () => {
    if (editEnabled !== -1) {
      const { total, data } = gridData;
      const newData = [...data];

      newData[editEnabled][EDIT_FIELD] = undefined;

      const newDataWithEditedFieldUpdated = checkIfFieldIsEditedWithNewData(
        newData,
        SAVEDGRIDDATA[0]
      );

      if (newDataWithEditedFieldUpdated[editEnabled]?.needToSave === true) {
        delete newDataWithEditedFieldUpdated[editEnabled].needToSave;
        let newSavedGridData = newDataWithEditedFieldUpdated;
        if (filter) {
          const lastSavedGridData = SAVEDGRIDDATA[SAVEDGRIDDATA.length - 1].data;
          newSavedGridData = lastSavedGridData.map((data) => {
            if (data.StoreId === newDataWithEditedFieldUpdated[editEnabled].StoreId) {
              return newDataWithEditedFieldUpdated[editEnabled];
            }
            return data;
          });
        }
        SAVEDGRIDDATA.push({
          data: newSavedGridData,
          total: newSavedGridData.length,
        });
      }

      setGridData({
        data: newDataWithEditedFieldUpdated,
        total: total,
      });

      setEditEnabled(-1);
    }
  };

  const checkIfFieldIsEditedWithNewData = (newData, initialDataFromGrid) => {
    let tempData = JSON?.parse(JSON?.stringify(newData));
    newData?.forEach((plan, planIndex) => {
      let editedFields = [...(plan?.edited || [])];
      if (editedFields) {
        editedFields?.forEach((editedColName) => {
          let dependentFields = dependentDataFieldsSecondary;
          if (dependentDataFields?.includes(editedColName)) {
            dependentFields = dependentDataFields;
          }
          if (
            tempData[planIndex][editedColName] ===
            initialDataFromGrid?.data[planIndex][editedColName] &&
            dependentFields?.includes(editedColName)
          ) {
            const otherdependentDataField = dependentFields.filter(
              (field) => field !== editedColName
            )[0];
            if (
              tempData[planIndex][otherdependentDataField] ===
              initialDataFromGrid?.data[planIndex][otherdependentDataField]
            ) {
              let newEditedFieldList = editedFields.filter(
                (e) => !dependentFields.includes(e)
              );
              tempData[planIndex].edited = [...newEditedFieldList];
            }
          } else if (
            tempData[planIndex][editedColName] ===
            initialDataFromGrid?.data[planIndex][editedColName]
          ) {
            let newEditedFieldList = editedFields.filter(
              (e) => e !== editedColName
            );
            tempData[planIndex].edited = [...newEditedFieldList];
          }
        });
      }
    });
    return tempData;
  };

  const customRowRender = (tr, props) => {
    return (
      <RowRender
        originalProps={props}
        tr={tr}
        exitEdit={exitEdit}
        editField={EDIT_FIELD}
      />
    );
  };

  const messagePopup = (msg, button, icon, dataItem) => {
    const callBackFn = async (msg) => {
      setMessageProps(null);
      switch (msg) {
        case "YES":
          let loadPlan = getSavedLoadPlan([dataItem], "RECOLLECT");
          const queryParams = getQueryParams("RECOLLECT");
          setShowLoader({
            className: ".workOrders_grid",
            msg: "loading",
          });
          const res = await recollectPlan(
            JSON.stringify(loadPlan),
            authContext,
            queryParams
          );
          console.log("onRecollectWO", res)
          if (res?.Status?.status === "Success") {
            await fetchStoreList();
            showToast("success", res?.Status?.statusMsg);
          } else {
            console.log("search plan api error", res);
          }
          setShowLoader(null);
          break;
        default:
          break;
      }
    };
    setMessageProps({
      title: "Warning",
      buttons: button,
      icon: icon,
      message: msg,
      fn: callBackFn,
    });
  };

  const handleRecollectClick = (dataItem) => {
    messagePopup(
      `${t("recollectwarning")} ${dataItem.AuditorId} ?`,
      // t("recollectwarning"),
      "YESNO",
      "WARNING",
      dataItem
    );

  };

  const RecollectBtnCell = (props) => {
    const { dataItem } = props;

    return (
      <td
        style={{ textAlign: "center", padding: 4 }}
        data-grid-dataitem-id={dataItem.customID}
      >
        {
          dataItem?.Status === "COLLECTED" &&
          (<Button
            title="Recollect"
            style={{
              color: "#fff",
              width: "100%",
            }}
            onClick={() => handleRecollectClick(dataItem)}
          >
            <img src={recollect} alt="recollect" />
          </Button>)
        }
      </td>
    );
  };

  const customCellRender = (td, props) => {
    switch (props.field) {
      case "recollect":
        return (
          <RecollectBtnCell dataItem={props.dataItem} />
        );
      case "AuditorId":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<ResourceIDCell {...props} />}
          />
        );
      case "store_upc_count":
        return (
          <td
            style={{ textAlign: "center", cursor: "pointer" }}
            data-grid-dataitem-id={props.dataItem.customID}
            onClick={() => setUpcWindow(props.dataItem)}
          >
            {props.dataItem[props.field]}
          </td>
        );
      case "PRIMARY_RESOURCE_NAME":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<ResourceNameCell {...props} />}
          />
        );
      case "SECONDARY_RESOURCE_IDS":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<SecondaryResourceIDCell {...props} />}
          />
        );
      case "SECONDARY_RESOURCE_NAME":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<SecondaryResourceNameCell {...props} />}
          />
        );

      case "StartDate":
      case "EndDate":
      case "Duedate":
      case "limitDate":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<DatePickerCell {...props} />}
          />
        );
      case "StartTime":
      case "EndTime":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<TimeIntervalCell {...props} />}
          />
        );
      case "collType":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<CollType {...props} />}
          />
        );
      case "limitTime":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<LimitTime {...props} />}
          />
        );
      case "collectionDays":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<CollectionDays {...props} />}
          />
        );
      case "estimatedEans":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<EstimatedEans {...props} />}
          />
        );
      case "noteCollection":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            selectedState={selectedState}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<NoteCollection {...props} />}
          />
        );
      case "remarks":
        return (
          <CustomCellDropDown
            jobAction={jobAction}
            originalProps={props}
            td={td}
            enterEdit={enterEdit}
            selectedState={selectedState}
            editField={EDIT_FIELD}
            handleDragStart={handleDragStart}
            handleDrag={handleDrag}
            cell={<PlainInputCell {...props} />}
          />
        );
      default:
        return (
          <NonEditableCell
            originalProps={props}
            td={td}
            enterEdit={enterEdit}
            editField={EDIT_FIELD}
          />
        );
    }
  };

  const auditorDetails = useSelector(selectAuditorList);
  console.log(auditorDetails,"auditorDetails")

  const getGridData = useMemo(() => {
    if (jobAction === "collected") {
      let filterAllCollected = result?.data?.filter((el) => {
        return el.Status === "COLLECTED" || el.Status === "OUTPUT_PROCESSED" || el.Status === "RECOLLECTION_INITIATED" || el.Status === "EXPORT_ERROR" || el.Status === "SENT_TO_FACTORY"

      });
      

      if (selectedRadio === "blockId") {
        const uniqueBlockIdData = Object.values(
          filterAllCollected?.reduce((acc, curr) => {
            if (!acc[curr.blockId]) {
              acc[curr.blockId] = curr; 
              // Keep the first occurrence of each blockId
            }
            return acc;
          }, {})
        )
          .sort((a, b) => a.blockId - b.blockId) // Sort by blockId
          .map((item, ind) => ({
            ...item,
            selected: selectedState[idGetter(item)],
            ID: ind + 1,
            customID: item.StoreId + (ind + 1),
          })); // Add extra fields
      
        return {
          data: uniqueBlockIdData,
          total: uniqueBlockIdData?.length,
        };
      }else{

        const gridContent = filterAllCollected?.map((item, ind) => {
          return {
            ...item,
            selected: selectedState[idGetter(item)],
            ID: ind + 1,
            customID: item.StoreId + (ind + 1),
          };
        });
        return {
          data: gridContent,
          total: gridContent.length,
        };

      }


      

    } else if (jobAction === "replan") {
      let JobActionStatus = result?.data?.filter(js => js.JobActionStatus === "Replan")
      if (JobActionStatus) {
        let removeAllCollected = JobActionStatus.filter((el) => {
          return el.Status !== "COLLECTED" && el.Status !== "OUTPUT_PROCESSED" && el.Status !== "RECOLLECTION_INITIATED" && el.Status !== "EXPORT_ERROR" && el.Status !== "SENT_TO_FACTORY"

        });

        if (selectedRadio === "blockId") {
          const uniqueBlockIdData = Object.values(
            removeAllCollected?.reduce((acc, curr) => {
              if (!acc[curr.blockId]) {
                acc[curr.blockId] = curr; 
                // Keep the first occurrence of each blockId
              }
              return acc;
            }, {})
          )
            .sort((a, b) => a.blockId - b.blockId) // Sort by blockId
            .map((item, ind) => ({
              ...item,
              selected: selectedState[idGetter(item)],
              ID: ind + 1,
              customID: item.StoreId + (ind + 1),
            })); // Add extra fields
        
          return {
            data: uniqueBlockIdData,
            total: uniqueBlockIdData?.length,
          };
        }else{

          const gridContent = removeAllCollected?.map((item, ind) => {
            return {
              ...item,
              selected: selectedState[idGetter(item)],
              ID: ind + 1,
              customID: item.StoreId + (ind + 1),
            };
          });
          return {
            data: gridContent,
            total: gridContent.length,
          };

        }

        
      }
    } else if (jobAction === "plan") {
      let JobActionStatus = result?.data?.filter(js => js.JobActionStatus === "Plan")
      if (JobActionStatus) {
        if (selectedRadio === "blockId") {
          const uniqueBlockIdData = Object.values(
            JobActionStatus?.reduce((acc, curr) => {
              if (!acc[curr.blockId]) {
                acc[curr.blockId] = curr; 
                // Keep the first occurrence of each blockId
              }
              return acc;
            }, {})
          )
            .sort((a, b) => a.blockId - b.blockId) // Sort by blockId
            .map((item, ind) => ({
              ...item,
              selected: selectedState[idGetter(item)],
              ID: ind + 1,
              customID: item.StoreId + (ind + 1),
            })); // Add extra fields
        
          return {
            data: uniqueBlockIdData,
            total: uniqueBlockIdData?.length,
          };
        }else{
          const gridContent = JobActionStatus.map((item, ind) => {
            return {
              ...item,
              ID: ind + 1,
              selected: selectedState[idGetter(item)],
              customID: item.StoreId + (ind + 1),
            };
          });
          return {
            data: gridContent,
            total: gridContent.length,
          };
        }
      }
    } else if (jobAction === "all") {
      if (selectedRadio === "blockId") {
        const uniqueBlockIdData = Object.values(
          result?.data?.reduce((acc, curr) => {
            if (!acc[curr.blockId]) {
              acc[curr.blockId] = curr; 
              // Keep the first occurrence of each blockId
            }
            return acc;
          }, {})
        )
          .sort((a, b) => a.blockId - b.blockId) // Sort by blockId
          .map((item, ind) => ({
            ...item,
            selected: selectedState[idGetter(item)],
            ID: ind + 1,
            customID: item.StoreId + (ind + 1),
          })); // Add extra fields
      
        return {
          data: uniqueBlockIdData,
          total: uniqueBlockIdData?.length,
        };
      }else{
        const gridContent = result?.data?.map((item, ind) => {
          return {
            ...item,
            ID: ind + 1,
            selected: selectedState[idGetter(item)],
            customID: item.StoreId + (ind + 1),
          };
        });
        return {
          data: gridContent,
          total: gridContent.length,
        };
      } 
    }
  }, [result, jobAction,selectedRadio]);

  const getSelectedGridData = useMemo(() => {
    if (jobAction === "collected") {
      let filterAllCollected = gridData?.data?.filter((el) => {
        return el.Status === "COLLECTED" || el.Status === "OUTPUT_PROCESSED" || el.Status === "RECOLLECTION_INITIATED" || el.Status === "EXPORT_ERROR" || el.Status === "SENT_TO_FACTORY"
      });
      const gridContent = filterAllCollected?.map((item, ind) => {
        return {
          ...item,
          selected: selectedState[idGetter(item)],
        };
      });
      return {
        data: gridContent,
        total: gridContent.length,
      };
    } else if (jobAction === "replan") {
      let JobActionStatus = gridData?.data?.filter(js => js.JobActionStatus === "Replan")
      if (JobActionStatus) {
        let removeAllCollected = JobActionStatus.filter((el) => {
          return el.Status !== "COLLECTED" && el.Status !== "OUTPUT_PROCESSED" && el.Status !== "RECOLLECTION_INITIATED" && el.Status !== "EXPORT_ERROR" && el.Status !== "SENT_TO_FACTORY"
        });

        const gridContent = removeAllCollected?.map((item, ind) => {
          return {
            ...item,
            selected: selectedState[idGetter(item)],
          };
        });

        return {
          data: gridContent,
          total: gridContent.length,
        };
      }
    } else if (jobAction === "plan") {
      let JobActionStatus = gridData?.data?.filter(js => js.JobActionStatus === "Plan")
      if (JobActionStatus) {
        const gridContent = JobActionStatus.map((item, ind) => {
          return {
            ...item,
            selected: selectedState[idGetter(item)],
          };
        });
        return {
          data: gridContent,
          total: gridContent.length,
        };
      }
    } else if (jobAction === "all") {
      const gridContent = gridData?.data?.map((item, ind) => {
        return {
          ...item,
          selected: selectedState[idGetter(item)],
        };
      });
      return {
        data: gridContent,
        total: gridContent.length,
      };
    }
  }, [selectedState]);

  useEffect(() => {
    const nrps_required = atob(localStorage.getItem(btoa("nrps_required")));
    if (nrps_required === "true") {
      setNrpsReport(nrps_required);
    }

    const upc_required = atob(localStorage.getItem(btoa("upc_required")));
    if (upc_required === 'true') {
      setUpcRedistribution(true)
    } else {
      setUpcRedistribution(false)
    }
  }, []);

  useEffect(() => {
    fetchStoreList();
  }, [authContext]);

  // useEffect(() => {
  //   fetchStoreList();
  // }, [selectedRadio]);

  useEffect(() => {
    if (shouldRefresh) {
      setTimeout(() => {
        fetchStoreList();
        setShouldRefresh(false);
      }, 3000)
    }
  }, [shouldRefresh]);

  useEffect(() => {
    document.getElementById('customCellComboBox')?.focus();
  })

  const dataStateChange = (event) => {
    let updatedState = createDataState([], event.dataState);
    setDataState(updatedState.dataState);
  };

  const onPageChange = async (e) => {
    const dataState = e.page;
    if (document.activeElement.tagName.toLowerCase() === 'td') {
      const rowIndex = parseInt(document.activeElement.parentElement.getAttribute('aria-rowindex'));
      if (rowIndex && ((rowIndex - 1) <= dataState.skip || (rowIndex + 1) >= (dataState.skip + pageSize))) {
        document.activeElement.blur();
      }
    }
    setDataState(dataState);
  };

  console.log(selectedRadio,"selectedRadio")

  const fetchStoreList = async () => {
    let id = 0;
    const params = {
      CompanyId: authContext?.CompanyID,
      page: Math.floor(dataState.skip / dataState.take) + 1,
      StartCount: dataState.skip,
      MaxCount: dataState.take,
      Jobid: JOB_ID,
      CollectionType: COLLECTION_TYPE,
    };
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await getStorePlanList(params, authContext);
   
    setFetchAllPlan(res)
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }
    if (res?.Response && Array.isArray(res.Response)) {

      const checkBlockId = res?.Response?.some((item) => item.blockId !== 0 && item.blockId !== null );
      setHasNonZeroBlockId(checkBlockId);

      let data =
        res?.Response?.map((item) => {
          const curPrimaryAud = auditorDetails?.find(
            (aud) => aud?.AuditorId === item?.AuditorId
          );
          let saList = [];
          let saNameList = [];
          if (item["SecondaryAuditors"]) {
            saList = item.SecondaryAuditors !== "" ? item.SecondaryAuditors.split("|") : "";
            saList.forEach((obj) => {
              const auditorobj = auditorDetails?.find(
                (aud) => aud?.AuditorId === obj
              );
              saNameList.push(auditorobj?.AuditorName);
            })
          }
          return {
            ...item,
            ID: ++id,
            customID: item.StoreId + (id),
            SECONDARY_RESOURCE_IDS: saList,
            OriginalSecondaryAuditorList: saList,
            SECONDARY_RESOURCE_NAME: saNameList,
            PRIMARY_RESOURCE_NAME: curPrimaryAud?.AuditorName,
          };
        }).sort(function (a, b) {
          return a.StoreId - b.StoreId;
        }) || [];
      const newResult = {
        data: data,
        total: res?.StoreCount || res?.Response?.length || dataState.take,
      };
      if(selectedRadio==="blockId"){
        if (Array.isArray(newResult.data)) {
          const uniqueBlockIdData = Object.values(
            newResult?.data?.reduce((acc, curr) => {
              if (!acc[curr.blockId]) {
                acc[curr.blockId] = curr; // Keep the first occurrence of each blockId
              }
              return acc;
            }, {})
          ).sort((a, b) => a.blockId - b.blockId); // Sort by blockI
          setGridData(
            {
              data: uniqueBlockIdData,
              total: uniqueBlockIdData?.length
            });
        } 
      }else{
        setFetchedData(newResult)
        setData(newResult);
      }

      // SAVEDGRIDDATA.splice(0, SAVEDGRIDDATA.length);
    } else {
      setFetchedData([]);
      console.log("search plan api error", res);
    }
    setShowLoader(null);
  };

  const handleDefaultViewClick = () => {
    onColumnsSubmit();
  };

  const handleSaveViewClick = async () => {
    const Json_Object = JSON.stringify(stateColumns);
    const params = {
      UserPrefReq: {
        Email: authContext?.SpocMail,
        Company_ID: authContext?.CompanyID,
        Grid_Name: GRID_NAME,
        Country_ID: authContext?.CountryCode,
        Json_Object,
      },
    };
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    const res = await updateUserPreferenceAction(
      JSON.stringify(params),
      authContext
    );
    if (checkInvalidSession(res)) {
      handleAuthContext();
      return;
    }

    if (res?.Response?.Status === "Success") {
      dispatch(
        searchUserPrefrence(
          JSON.stringify({
            UserPrefReq: {
              Email: authContext?.SpocMail,
              Company_ID: authContext?.CompanyID,
            },
          })
        )
      );
      showToast("success", t(res?.Response?.Message || "success"));
    } else {
      showToast("error", t(res?.Response?.Message || "dataNotFound"));
    }
    setShowLoader(null);
  };

  const onSelectionChange = (event) => {
    const visiableColList = stateColumns.filter((col) => col.show === true);
    let newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedState(newSelectedState);
    let positionData = {
      startRowIndex: event.startRowIndex + dataState.skip,
      endRowIndex: event.endRowIndex + dataState.skip,
      startColIndex: event.startColIndex,
      endColIndex: event.endColIndex,
    };

    setPosition(positionData);
    if (event.startColIndex !== event.endColIndex) return;

    if (
      visiableColList[event.startColIndex].editable &&
      event.endRowIndex === event.startRowIndex
    ) {
      setDrag(false);
    } else if (!visiableColList[event.startColIndex].editable) {
      exitEdit();
    }
  };

  const onNavigationAction = (event) => {
    setPosition({
      startRowIndex: event?.focusElement?.parentElement?.rowIndex + dataState.skip,
      endRowIndex: event?.focusElement?.parentElement?.rowIndex + dataState.skip,
      startColIndex: event.focusElement.cellIndex,
      endColIndex: event.focusElement.cellIndex,
    })
    const newSelectedState = {};
    newSelectedState[event?.focusElement?.attributes?.["data-grid-dataitem-id"]?.value] = [event.focusElement.cellIndex];
    setSelectedState(newSelectedState);
  }

  const onKeyDownHandle = (event) => {
    if (event.nativeEvent.keyCode === 90 && event.nativeEvent.ctrlKey) {
      undoGridEdit();
    }
  }

  const handleDragStart = (event) => {
    setDragStartPosition(position);
  }

  const handleDrag = () => {
    setDrag(true);
  }

  useEffect(() => {
    if (drag) {
      fillHandle();
    }
  }, [drag])

  const fillHandle = () => {
    if (dragStartPosition.startColIndex !== position.endColIndex) return;

    const visiableColList = stateColumns.filter((col) => col.show === true);
    if (
      visiableColList[dragStartPosition.startColIndex].editable &&
      position.endRowIndex > dragStartPosition.startRowIndex
    ) {
      let selectedGrid = visiableColList[dragStartPosition.startColIndex].field;
      let dependentFields = dependentDataFieldsSecondary;
      if (dependentDataFields.includes(selectedGrid)) {
        dependentFields = dependentDataFields;
      }
      let temp = [...gridData.data];
      let storeIds = [];
      if (dependentFields.includes(selectedGrid)) {
        const otherdependentDataField = dependentFields.filter(
          (field) => field !== selectedGrid
        )[0];
        temp.map((item, index) => {
          if (
            dragStartPosition.startRowIndex <= index &&
            index <= position.endRowIndex
          ) {
            item[selectedGrid] = gridData.data[dragStartPosition.startRowIndex][selectedGrid];
            item[otherdependentDataField] =
              gridData.data[dragStartPosition.startRowIndex][otherdependentDataField];
            item['LastUpdBy'] = authContext?.SpocMail;
            if (Array.isArray(storeIds[item.StoreId])) {
              storeIds[item.StoreId].push(item.customID);
            } else {
              storeIds[item.StoreId] = [item.customID];
            }
            if (Array.isArray(item["edited"])) {
              item["edited"].push(selectedGrid);
              item["edited"].push(otherdependentDataField);
            } else item["edited"] = [selectedGrid, otherdependentDataField];
          }
          return item;
        });

        temp.map((item, index) => {
          if (
            dragStartPosition.startRowIndex > index ||
            index > position.endRowIndex
          ) {
            if (Array.isArray(storeIds[item.StoreId]) && !storeIds[item.StoreId].includes(item.customID)) {
              item[selectedGrid] = gridData.data[dragStartPosition.startRowIndex][selectedGrid];
              item[otherdependentDataField] =
                gridData.data[dragStartPosition.startRowIndex][otherdependentDataField];
              if (Array.isArray(item["edited"])) {
                item["edited"].push(selectedGrid);
                item["edited"].push(otherdependentDataField);
              } else item["edited"] = [selectedGrid, otherdependentDataField];
            }
          }
          return item;
        });
      } else {
        temp.map((item, index) => {
          if (
            dragStartPosition.startRowIndex <= index &&
            index <= position.endRowIndex
          ) {
            item[selectedGrid] = gridData.data[dragStartPosition.startRowIndex][selectedGrid];
            item['LastUpdBy'] = authContext?.SpocMail;
            if (Array.isArray(storeIds[item.StoreId])) {
              storeIds[item.StoreId].push(item.customID);
            } else {
              storeIds[item.StoreId] = [item.customID];
            }
            if (Array.isArray(item["edited"]))
              item["edited"].push(selectedGrid);
            else item["edited"] = [selectedGrid];
          }
          return item;
        });

        temp.map((item, index) => {
          if (
            dragStartPosition.startRowIndex > index ||
            index > position.endRowIndex
          ) {
            if (Array.isArray(storeIds[item.StoreId]) && !storeIds[item.StoreId].includes(item.customID)) {
              item[selectedGrid] = gridData.data[dragStartPosition.startRowIndex][selectedGrid];
              if (Array.isArray(item["edited"]))
                item["edited"].push(selectedGrid);
              else item["edited"] = [selectedGrid];
            }
          }
          return item;
        });
      }
      const newDataWithEditedFieldUpdated = checkIfFieldIsEditedWithNewData(
        temp,
        SAVEDGRIDDATA[0]
      );
      const newvalue = {
        data: newDataWithEditedFieldUpdated,
        total: gridData.total,
      };
      if (filter) {
        const lastSelectedItem = JSON.parse(JSON.stringify(SAVEDGRIDDATA[SAVEDGRIDDATA.length - 1].data));
        const selectedStateKeys = Object.keys(selectedState);
        let tempData = [...lastSelectedItem];
        lastSelectedItem.forEach((val, key) => {
          if (selectedStateKeys.includes(val.customID)) {
            let otherdependentDataField = "";
            if (dependentFields.includes(selectedGrid)) {
              otherdependentDataField = dependentFields.filter(
                (field) => field !== selectedGrid
              )[0];
            }
            tempData[key][selectedGrid] = gridData.data[dragStartPosition.startRowIndex][selectedGrid];
            if (otherdependentDataField) {
              tempData[key][otherdependentDataField] = gridData.data[dragStartPosition.startRowIndex][otherdependentDataField];
            }
            tempData[key]['LastUpdBy'] = authContext?.SpocMail;
            if (Array.isArray(tempData[key]["edited"])) {
              tempData[key]["edited"].push(selectedGrid);
              if (otherdependentDataField) {
                tempData[key]["edited"].push(otherdependentDataField);
              }
            } else {
              tempData[key]["edited"] = [selectedGrid];
              if (otherdependentDataField) {
                tempData[key]["edited"].push(otherdependentDataField);
              }
            };
          }
        });
        const newTempDataWithEditedFieldUpdated = checkIfFieldIsEditedWithNewData(
          tempData,
          SAVEDGRIDDATA[0]
        );
        let newTempValue = {
          data: newTempDataWithEditedFieldUpdated,
          total: newTempDataWithEditedFieldUpdated.length,
        };
        SAVEDGRIDDATA.push(newTempValue);
      } else {
        SAVEDGRIDDATA.push(newvalue);
      }

      setSelectedState({});
      setTimeout(() => {
        setGridData(newvalue);
      }, 100);
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const excelExport = async () => {
    const params = {
      CountryId: authContext?.CountryCode,
      CompanyId: authContext?.CompanyID,
      Jobid: JOB_ID,
      CollectionType: COLLECTION_TYPE,
      Action: capitalizeFirstLetter(jobAction),
      BlockId:selectedRadio === "storeId" ? false : true
      // Action: JOB_ACTION,
    };
    setShowLoader({
      className: ".workOrders_grid",
      msg: "loading",
    });
    await downloadPlan(params, authContext);
    setShowLoader(null);
  };

  const handleUploadWindow = (dataItem) => {
    setUploadWindow(!uploadWindow);
  };

  const itemChange = (event) => {
    let field = event.field || "";
    const newValue = event.value;
    event.dataItem[field] = newValue;
    const editColumn = columns.find((col) => col.field === field);
    if (newValue || (newValue === null && editColumn.isNullable)) {
      let newData = gridData?.data.map((item) => {
        if (item?.StoreId === event.dataItem?.StoreId) {
          event.dataItem[field] = newValue === '00000' ? '' : newValue;
          if (
            (field === "SECONDARY_RESOURCE_IDS" || field === "SECONDARY_RESOURCE_NAME") &&
            event.dataItem[field].length ===
            event.dataItem["OriginalSecondaryAuditorList"].length &&
            event.dataItem[field].every(function (element, index) {
              return (
                element ===
                event.dataItem["OriginalSecondaryAuditorList"][index]
              );
            })
          ) {
            item.edited = item.edited?.filter(
              (fieldName) => fieldName !== field
            );
          } else {
            if (newValue) {
              const { edited = [] } = item;
              item.edited = [...edited, field];
            } else {
              item.edited = item.edited?.filter(
                (fieldName) => fieldName !== field
              );
            }
          }
          item[field] = newValue === '00000' ? '' : newValue;
          item['LastUpdBy'] = authContext?.SpocMail;
        }
        return item;
      });
      setGridData((prev) => ({ ...prev, data: newData }));
    }
  };
  const showGridTools = jobAction === "plan" || jobAction === "replan";

  const undoGridEdit = () => {
    if (SAVEDGRIDDATA.length > 1) {
      SAVEDGRIDDATA.pop();
      const lastItem = SAVEDGRIDDATA[SAVEDGRIDDATA.length - 1];
      if (filter) {
        const filterData = filterBy(lastItem.data, filter);
        setGridData({
          data: filterData,
          total: filterData.length,
        });
      } else {
        setGridData(lastItem);
      }
    }
  };

  const checkLockStatusToProceed = async () => {
    const params = {
      Action: "SAVE",
      JobId: JOB_ID,
      CompanyId: authContext?.CompanyID,
      User: authContext?.SpocMail || "",
    };
    const res = await getLockStatus(params, authContext);
    if (res?.root?.Status === "Unlocked") {
      return true;
    } else {
      showToast("error", res?.CategoryOp?.error);
      setShowLoader(null);
      return false;
    }
  };

  function updatedBlockIdValues(updatedArrobject) {
    console.log(updatedArrobject,"updatedArrobject")
    const changesArray = [];
  
    // Iterate through updated array
    updatedArrobject.forEach((updatedObj) => {
      const blockId = updatedObj.blockId;
  
      // Find the original object for this blockId
      const originalObj = fetchedData?.data?.find(
        (data) => data.blockId === blockId
      );
  
      if (originalObj) {
        // Create a new object using keys only from updatedObj
        const mergedObject = {};
  
        Object.keys(updatedObj).forEach((key) => {
          // Include the value from updatedObj or fall back to the value from originalObj
          mergedObject[key] =
            updatedObj[key] !== undefined ? updatedObj[key] : originalObj[key];
        });
  
        changesArray.push(mergedObject);
      }
    });
  
    console.log(changesArray, "changesArray");
  
    return changesArray;
  }

  // function updatedBlockIdValues(updatedArrobject) {
  //   const changesMap = new Map();
  
  //   // Helper function to compare objects and find changed values
  //   function findChangedValues(original, updated) {
  //     const changes = {};
  
  //     for (const key in original) {
  //       if (original[key] !== updated[key]) {
  //         changes[key] = {
  //           oldValue: original[key],
  //           newValue: updated[key],
  //         };
  //       }
  //     }
  
  //     return Object.keys(changes).length > 0 ? changes : null;
  //   }
  
  //   // Iterate through updated array
  //   updatedArrobject.forEach((updatedObj) => {
  //     const blockId = updatedObj.blockId;
  //     console.log(fetchedData,blockId,"fetchedDatablockId")
  //     // Find the original object for this blockId
  //     const originalObj = fetchedData?.data?.find(
  //       (data) => data.blockId === blockId
  //     );

  //     console.log(originalObj,"fetchedDatablockId123")
  
  //     if (originalObj) {
  //       const changedValues = findChangedValues(originalObj, updatedObj);
  
  //       if (changedValues) {
  //         if (!changesMap.has(blockId)) {
  //           changesMap.set(blockId, []);
  //         }
  //         changesMap.get(blockId).push(changedValues);
  //       }
  //     }
  //   });
  
  //   // return changesMap;
  //   console.log(changesMap,"changesMap")
  // }

  // getSavedLoadPlan


  function findEditedObjectsWithValues(data) {
    return data
        .filter(item => item.edited && item.edited.length > 0) // Filter objects with an "edited" key
        .map(item => {
            const editedObject = { blockId: item.blockId }; // Start with the blockId
            item.edited.forEach(key => {
                if (item[key] !== undefined) {
                    editedObject[key] = item[key]; // Add key-value pairs only if value is not undefined
                }
            });
            return editedObject;
        })
        .filter(obj => Object.keys(obj).length > 1); // Exclude objects where only blockId exists
}
  

  const handleOnStartCollectionBtnClick = async () => {
    

      console.log(SAVEDGRIDDATA,"SAVEDGRIDDATASAVEDGRIDDATA")
      const finalSavedData = SAVEDGRIDDATA?.[SAVEDGRIDDATA?.length - 1]?.data;
      console.log(finalSavedData,"finalSavedDatafinalSavedData")
      const validationRes = validateLoadPlan(finalSavedData);

      const changedObjects = findEditedObjectsWithValues(gridData?.data)
  
      console.log(finalSavedData,gridData?.data,previousgrid.data,changedObjects,"finalSavedData")


  
      let loadPlan = getSavedLoadPlan(finalSavedData, "SUBMIT");
      console.log(loadPlan,"loadPlanloadPlan")
        const secondaryAuditorsOnly = loadPlan?.LoadPlan.filter((el) => el.SecondaryAuditors && !el.AuditorId);
        console.log(secondaryAuditorsOnly,"secondaryAuditorsOnly")
        if (secondaryAuditorsOnly.length > 0) {
          showToast("warning", t("atleastOneAudIdValidation"));
          return
        }
      

      const queryParams = getQueryParams("SUBMIT");
      let tempAuditorIdValidation = loadPlan?.LoadPlan.map((el) => el.AuditorUniqueID !== "")
      let tempFalsyAuditorIdValidation = tempAuditorIdValidation.some((chk) => chk === true)
      console.log(secondaryAuditorsOnly,tempAuditorIdValidation,tempFalsyAuditorIdValidation,"secondaryAuditorsOnly")
      // if (!tempFalsyAuditorIdValidation) {
      //   showToast("warning", t("atleastOneAudIdValidation"), {
      //     position: toast.POSITION.TOP_CENTER,
      //   });
      //   return
      // }
      let tempAuditorIds = loadPlan?.LoadPlan.filter((el) => el.AuditorUniqueID !== "")
  
      if (tempAuditorIds.length > 0) {
        let tempStartEnd = tempAuditorIds.find((el) => {
          return (
            el.StartDate === "" || el.EndDate === ""
          )
        })
        if (tempStartEnd) {
          showToast("warning", t("startAndEndDateValidation"), {
            position: toast.POSITION.TOP_CENTER,
          });
          return
        }
  
      }
      let RA_Validation = fetchAllPlan?.Response?.some(ra => ra.CollectionType === "RA");
      let WeeklyFrequency = fetchAllPlan?.Response?.some(wf => wf.Frequency === "WEEKLY");
      let tempAuditorIdValidationForPartial = loadPlan?.LoadPlan.filter((el) => el.AuditorUniqueID === "")
      if (fetchAllPlan?.template_store !== "Y" && tempAuditorIdValidationForPartial.length > 0 && RA_Validation) {
        showToast("error", t("RA Collection Cannot Be Partially Committed. Please Either Finish And Submit Or Save"), {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      } else if (fetchAllPlan?.template_store !== "Y" && WeeklyFrequency === true && tempAuditorIdValidationForPartial.length > 0) {
        showToast("error", t("WeeklyfrequencyplancannotbepartiallycommittedPleaseeitherfinishandSubmitorSave"), {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
  
      let RA_Auditor = loadPlan?.LoadPlan.find(ra => ra.AuditorId !== "");
      let temp_RA_Validation = loadPlan?.LoadPlan.filter((el) => {
        if (RA_Auditor) {
          return (
            el.DueDate && el.StartTime && el.EndTime
          )
        }
      })
      if (RA_Validation && temp_RA_Validation?.length === 0) {
        showToast("error", "Visit Date, Start Time and End Time should not be empty", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
  
      let updatedRowsValue = []
      if(selectedRadio === "blockId"){
        //  const changesArray = findEditedObjectsWithValues(gridData?.data)
        // console.log("changesArray0",changesArray,loadPlan?.LoadPlan)
        // if(changesArray?.length === loadPlan?.LoadPlan?.length ){
        //   updatedRowsValue = loadPlan?.LoadPlan
        //   console.log(updatedRowsValue,"changesArray1")
        // }else{
          if(changedObjects.length>0)
            updatedRowsValue = getUpdatedRowsTwo(loadPlan?.LoadPlan?.[0],changedObjects,loadPlan?.LoadPlan)
          else
            updatedRowsValue = getUpdatedRowsThree(loadPlan?.LoadPlan?.[0],changedObjects,loadPlan?.LoadPlan)

          console.log(updatedRowsValue,"changesArray2")
        // }
      }
      setShowLoader({
        className: ".workOrders_grid",
        msg: "loading",
      });
  
      
  
      const res = await saveStorePlan(
        selectedRadio === "blockId" ?  JSON.stringify({LoadPlan:updatedRowsValue}) : JSON.stringify(loadPlan),
        authContext,
        queryParams
      );
      if (checkInvalidSession(res)) {
        handleAuthContext();
        return;
      }
      if (res?.Status?.status === "Success") {
        await fetchStoreList();
        showToast("success", res?.Status?.statusMsg);
        history.push("/work_orders");
      } else {
        console.log("search plan api error", res);
      }
      setShowLoader(null);
    

    // if (validationRes.status !== "success") {
    //   showToast("error", validationRes.message);
    //   return;
    // } else {
    //   let loadPlan = getSavedLoadPlan(finalSavedData, "SUBMIT");
    //   const queryParams = getQueryParams("SUBMIT");
    //   setShowLoader({
    //     className: ".workOrders_grid",
    //     msg: "loading",
    //   });
    //   const res = await saveStorePlan(
    //     JSON.stringify(loadPlan),
    //     authContext,
    //     queryParams
    //   );
    //   if (res?.Status?.status === "Success") {
    //     await fetchStoreList();
    //     showToast("success", res?.Status?.statusMsg);
    //     history.push("/work_orders");
    //   } else {
    //     console.log("search plan api error", res);
    //   }
    //   setShowLoader(null);
    // }
  };

//   function getUpdatedRows(updatedRows) {
//     // Create a map for quick lookup of updates by blockId (as strings)
//     if(fetchedData.length>0){
//       const updatesMap = new Map();
//       updatedRows.forEach(row => updatesMap.set(String(row.BlockId), row));
//       // Filter and update rows that match a blockId in updatedRows
//       return fetchedData?.filter(row => updatesMap.has(String(row.BlockId)))?.map(row => ({ ...row, ...updatesMap.get(String(row.BlockId)) }));
//     }
// }

function updatedBlockIdValuesTwo(updatedArrobject) {
  const changesArray = [];

  // Helper function to compare objects and find changed values
  function findChangedValues(original, updated) {
    const changes = {};

    for (const key in updated) {
      if (original[key] !== updated[key]) {
        changes[key] = updated[key]; // Only store the new value
      }
    }

    return Object.keys(changes).length > 0 ? changes : null;
  }

  // Iterate through updated array
  updatedArrobject.forEach((updatedObj) => {
    const blockId = updatedObj.blockId;

    // Find the original object for this blockId
    const originalObj = fetchedData?.data?.find(
      (data) => data.blockId === blockId
    );

    if (originalObj) {
      const changedValues = findChangedValues(originalObj, updatedObj);

      if (changedValues) {
        changesArray.push({
          blockId: blockId,
          ...changedValues, // Include only changed fields with new values
        });
      }
    }
  });
  //console.log(changesArray,"changesArraychangesArray")
   return changesArray;
}

function getUpdatedRowsTwo(keyObject, updatedRows, allKeyValuesArray) {
  console.log(keyObject, updatedRows, allKeyValuesArray, "keyObject, updatedRows, allKeyValuesArray");

  if (fetchedData?.data?.length > 0) {
    // Create maps for quick lookup by blockId
    const updatesMap = new Map();
    updatedRows?.forEach(row => updatesMap.set(String(row.blockId), row));

    const allKeyValuesMap = new Map();
    allKeyValuesArray?.forEach(row => allKeyValuesMap.set(String(row.blockId), row));

    // Filter and update rows that match a blockId in updatedRows
    const updatedRowsResult = fetchedData?.data
      ?.filter(row => updatesMap.has(String(row.blockId))) // Check if blockId exists in the updates map
      ?.map(row => {
        const updatedData = updatesMap.get(String(row.blockId)); // Data from updatedRows
        const fallbackData = allKeyValuesMap.get(String(row.blockId)); // Data from allKeyValuesArray
        const result = {};

        // Populate result with keys from keyObject
        Object.keys(keyObject).forEach(key => {
          result[key] =
            updatedData && updatedData.hasOwnProperty(key)
              ? updatedData[key] // Use updated value if available
              : row.hasOwnProperty(key)
              ? row[key] // Use original value from fetchedData.data
              : fallbackData?.[key]; // Use fallback value from allKeyValuesArray
        });

        return result; // Return the fully populated row
      });

    console.log(updatedRowsResult, "updatedRowsResult");
    return updatedRowsResult || []; // Return the result or an empty array
  }

  return []; // Return an empty array if fetchedData is not available
}

function getUpdatedRowsThree(keyObject, updatedRows, allKeyValuesArray) {
  console.log(keyObject, updatedRows, allKeyValuesArray, "keyObject, updatedRows, allKeyValuesArray");

  if (fetchedData?.data?.length > 0) {
    // Create maps for quick lookup by blockId
    const updatesMap = new Map();
    updatedRows?.forEach(row => updatesMap.set(String(row.blockId), row));

    const allKeyValuesMap = new Map();
    allKeyValuesArray?.forEach(row => allKeyValuesMap.set(String(row.blockId), row));

    // Check if updatedRows has any rows
    const hasUpdatedRows = updatedRows?.length > 0;

    const updatedRowsResult = fetchedData?.data.map(row => {
      const updatedData = hasUpdatedRows ? updatesMap.get(String(row.blockId)) : null; // Data from updatedRows (if available)
      const fallbackData = allKeyValuesMap.get(String(row.blockId)); // Data from allKeyValuesArray
      const result = {};

      // Populate result with keys from keyObject
      Object.keys(keyObject).forEach(key => {
        result[key] =
          updatedData && updatedData.hasOwnProperty(key)
            ? updatedData[key] // Use updated value if available
            : row.hasOwnProperty(key)
            ? row[key] // Use original value from fetchedData.data
            : fallbackData?.[key]; // Use fallback value from allKeyValuesArray
      });

      return result; // Return the fully populated row
    });

    console.log(updatedRowsResult, "updatedRowsResult");
    return updatedRowsResult; // Return the result array
  }

  return []; // Return an empty array if fetchedData is not available
}

function getUpdatedRows(keyObject, updatedRows) {

  console.log(keyObject, updatedRows,"keyObject, updatedRows")

  if (fetchedData?.data?.length > 0) {
    // Create a map for quick lookup of updates by BlockId (as strings)
    const updatesMap = new Map();
    updatedRows?.forEach(row => updatesMap.set(String(row.blockId), row));

    // Filter and update rows that match a BlockId in updatedRows
    const updatedRowsResult = fetchedData?.data
      ?.filter(row => updatesMap.has(String(row.blockId))) // Check if BlockId exists in the updates map
      ?.map(row => {
        const updatedData = updatesMap.get(String(row.blockId));
        const result = {};

        // Include only keys from keyObject
        Object.keys(keyObject).forEach(key => {
          result[key] = updatedData.hasOwnProperty(key)
            ? updatedData[key] // Use updated value if available
            : row[key]; // Otherwise, keep the original value
        });

        return result; // Return the filtered and updated row
      });

      console.log(updatedRowsResult,"updatedRowsResult")



    return updatedRowsResult  // Return the result or an empty array
  } // Return an empty array if fetchedData is not available
}


// function getUpdatedRowsStartCollection(updatedRows) {
//   if (fetchedData?.data?.length > 0) {
//     // Create a map for quick lookup of updates by blockId
//     const updatesMap = new Map();
//     updatedRows.forEach(row => updatesMap.set(String(row.blockId), row));

//     // Filter and process only rows with matching blockIds
//     const updatedRowsResult = updatedRows.map(updatedData => {
//       const blockId = String(updatedData.blockId);

//       // Find the original object for the matching blockId
//       const originalObj = fetchedData?.data.find(row => String(row.blockId) === blockId);

//       if (originalObj) {
//         const result = {};
//         // Keep only the keys from `updatedRows` and merge updated values
//         Object.keys(updatedData).forEach(key => {
//           result[key] = updatedData[key] !== undefined ? updatedData[key] : originalObj[key];
//         });

//         return result;
//       }

//       // If no original object is found, return the updated data as is
//       return updatedData;
//     });

//     console.log(updatedRowsResult, updatesMap, "updatedRowsResult");

//     return updatedRowsResult;
//   }

//   return []; // Return an empty array if fetchedData is empty or undefined
// }

  const handleOnSaveButtonClick = async () => {
    if (SAVEDGRIDDATA.length >= 1) {
      console.log(SAVEDGRIDDATA,"SAVEDGRIDDATASAVEDGRIDDATASAVEDGRIDDATA")
      const finalSavedData = SAVEDGRIDDATA[SAVEDGRIDDATA.length - 1].data;
      console.log(finalSavedData,"finalSavedDatafinalSavedData")
      const validationRes = validateLoadPlan(finalSavedData);


      let lockStatus = await checkLockStatusToProceed();
      if (lockStatus) {
        let loadPlan = getSavedLoadPlan(finalSavedData, "SAVE");
        console.log(loadPlan,"loadPlan")
        const queryParams = getQueryParams("SAVE");
        const secondaryAuditorsOnly = loadPlan?.LoadPlan.filter((el) => el.SecondaryAuditors && !el.AuditorId);
        if (secondaryAuditorsOnly.length > 0) {
          showToast("warning", t("atleastOneAudIdValidation"));
          return;
        }
        let updatedRowsValue = []
        if(selectedRadio === "blockId"){
          console.log(fetchedData,loadPlan?.LoadPlan,"fetchedDatafetchedData")
          const changesArray = updatedBlockIdValuesTwo(loadPlan?.LoadPlan)
          updatedRowsValue = getUpdatedRows(loadPlan?.LoadPlan?.[0],changesArray)

        }
        console.log("loadPlanstart", updatedRowsValue, loadPlan)
        setShowLoader({
          className: ".workOrders_grid",
          msg: "loading",
        });
        const res = await saveStorePlan(
          selectedRadio === "blockId" ?  JSON.stringify({LoadPlan:updatedRowsValue}) : JSON.stringify(loadPlan),
          authContext,
          queryParams
        );
        console.log("onsaveWO", res)
        if (res?.Status?.status === "Success") {
          await fetchStoreList();
          setFilter(null);
          showToast("success", res?.Status?.statusMsg);
        } else {
          console.log("search plan api error", res);
        }
        setShowLoader(null);
      }

      // if (validationRes.status !== "success") {
      //   showToast("error", validationRes.message);
      //   return;
      // } else {
      //   let lockStatus = await checkLockStatusToProceed();
      //   if (lockStatus) {
      //     let loadPlan = getSavedLoadPlan(finalSavedData, "SAVE");
      //     const queryParams = getQueryParams("SAVE");
      //     setShowLoader({
      //       className: ".workOrders_grid",
      //       msg: "loading",
      //     });
      //     const res = await saveStorePlan(
      //       JSON.stringify(loadPlan),
      //       authContext,
      //       queryParams
      //     );
      //     if (res?.Status?.status === "Success") {
      //       await fetchStoreList();
      //       showToast("success", res?.Status?.statusMsg);
      //     } else {
      //       console.log("search plan api error", res);
      //     }
      //     setShowLoader(null);
      //   }
      // }
    } 
    
  };

  
  const handleOnSubmitButtonClick = async () => {
    const finalSavedData = SAVEDGRIDDATA[SAVEDGRIDDATA.length - 1].data;
    const validationRes = validateLoadPlan(finalSavedData);
    if (validationRes.status !== "success") {
      showToast("error", validationRes.message);
      return;
    } else {
      let loadPlan = getNrpsSavedLoadPlan(finalSavedData, "NRPSSUBMIT");
      // const queryParams = getNrpsQueryParams("SUBMIT","NRPS_UPLOAD");
      setShowLoader({
        className: ".workOrders_grid",
        msg: "loading",
      });
      const res = await nrpsSubmitPlan(
        JSON.stringify(loadPlan),
        authContext
        // queryParams
      );
      if (checkInvalidSession(res)) {
        handleAuthContext();
        return;
      }
      if (res?.Status?.status === "Success") {
        await fetchStoreList();
        showToast("success", res?.Status?.statusMsg);
        history.push("/work_orders");
      } else {
        console.log("search plan api error", res);
      }
      setShowLoader(null);
    }
  };

  // const handleOnSubmitButtonClick = async () => {
  //   if (SAVEDGRIDDATA.length > 1) {
  //     const finalSavedData = SAVEDGRIDDATA[SAVEDGRIDDATA.length - 1].data;
  //     const validationRes = validateLoadPlan(finalSavedData);
  //     if (validationRes.status !== "success") {
  //       showToast("error", validationRes.message);
  //       return;
  //     } else {
  //       let lockStatus = await checkLockStatusToProceed();
  //       if (lockStatus) {
  //         let loadPlan = getSavedLoadPlan(finalSavedData, "SUBMIT");
  //         const queryParams = getQueryParams("SUBMIT");
  //         setShowLoader({
  //           className: ".workOrders_grid",
  //           msg: "loading",
  //         });
  //         const res = await saveStorePlan(
  //           JSON.stringify(loadPlan),
  //           authContext,
  //           queryParams
  //         );
  //         if (res?.Status?.status === "Success") {
  //           await fetchStoreList();
  //           showToast("success", res?.Status?.statusMsg);
  //         } else {
  //           console.log("search plan api error", res);
  //         }
  //         setShowLoader(null);
  //       }
  //     }
  //   } else if (SAVEDGRIDDATA.length == 1) {
  //     showToast("error", "No Record is changed");
  //   }
  // };


  const getQueryParams = (action) => {
    let countryId = "";
    //getting multiple countrycode, so taking the last one
    let countryCodeList = authContext?.CountryCode.split(",");
    if (Array.isArray(countryCodeList))
      // countryId = countryCodeList[countryCodeList.length - 1];
      countryId = Math.min(...countryCodeList);
    console.log("countryId", countryId)

    return {
      Action: action,
      Country: workOrderDetail.Country,
      // Country: countryId,
      CollectionType: COLLECTION_TYPE,
      Job_id: JOB_ID,
      CompanyId: authContext?.CompanyID,
      User: authContext?.SpocMail || "",
    };
  };

  const getNrpsQueryParams = (action, status = "") => {
    let countryId = "";
    //getting multiple countrycode, so taking the last one
    let countryCodeList = authContext?.CountryCode.split(",");
    if (Array.isArray(countryCodeList))
      // countryId = countryCodeList[countryCodeList.length - 1];
      countryId = Math.min(...countryCodeList);

    return {
      Action: action,
      Country: countryId,
      CollectionType: COLLECTION_TYPE,
      Job_id: JOB_ID,
      CompanyId: authContext?.CompanyID,
      User: authContext?.SpocMail || "",
      Plan_Status: status,
      WeekNo: WEEK_NO,
    };
  };

  const  validateLoadPlan = (finalSavedData) => {
    console.log(finalSavedData)
    if(finalSavedData !== undefined){
      let result = {
        status: "success",
        message: "validated successfully",
      };
      finalSavedData?.map((x) => {
        if (
          moment(x.Duedate).format("MM-DD-YYYY") >
          moment(x.EndDate).format("MM-DD-YYYY")
        ) {
          result = {
            status: "failure",
            message: "Visit Date Is Not In Between Start And End Date",
          };
        }
      });
      return result;
    }
  };

  function getDateValue(value) {
    if (!value) {
      return '';
    }
    return moment(value).format('MM/DD/YYYY');
  }

  const getSavedLoadPlan = (finalSavedData, action) => {
    let storeList = [];
    finalSavedData.map((x) => {
      const auditor = auditorDetails?.find(
        (aud) => aud?.AuditorId === x?.AuditorId
      );
      const loadPlanObj = {
        StoreId: x.StoreId,
        StoreName: x.StoreName,
        Frequency: x.Frequency,
        Period: x.Period,
        AuditorId: x.AuditorId,
        blockId:x.blockId,
        collType: x.collType ? x.collType : "",
        limitTime: x.limitTime ? x.limitTime : "",
        // limitDate: x.limitDate,
        limitDate: getDateValue(x.limitDate),
        collectionDays: x.collectionDays ? x.collectionDays : "0",
        estimatedEans: x.estimatedEans ? x.estimatedEans : "",
        noteCollection: x.noteCollection ? x.noteCollection : "",
        AuditorUniqueID: auditor != undefined ? auditor.AuditorUniqueId : "",
        SecondaryAuditors: x.SECONDARY_RESOURCE_IDS.join("|"),
        // StartDate: moment(x.StartDate).format("MM/DD/YYYY"),
        StartDate: getDateValue(x.StartDate),
        EndDate: getDateValue(x.EndDate),
        // EndDate: moment(x.EndDate).format("MM/DD/YYYY"),
        StartTime: x.StartTime,
        EndTime: x.EndTime,
        // DueDate: x.Duedate,
        DueDate: getDateValue(x.Duedate),
        Status: x.Status,
        remarks: x.remarks,
        store_address: x.store_address,
        Outbound_Status_FromFMS:
          x.JobActionStatus === "Replan" ? "REASSIGNED" : "",
      };
      if (action === "SUBMIT" || action === "NRPSSUBMIT" || action === "RECOLLECT") {
        storeList.push(loadPlanObj);
      } else if (
        x.edited !== undefined &&
        x.edited.length > 0 &&
        (action === "SAVE")
      ) {
        storeList.push(loadPlanObj);
      }
    });
    return { LoadPlan: storeList };
  };

  const getNrpsSavedLoadPlan = (finalSavedData, action) => {
    let storeList = [];
    let countryId = "";
    let countryCodeList = authContext?.CountryCode.split(",");
    if (Array.isArray(countryCodeList))
      // countryId = countryCodeList[countryCodeList.length - 1];
      countryId = Math.min(...countryCodeList);
    finalSavedData.map((x) => {
      const auditor = auditorDetails?.find(
        (aud) => aud?.AuditorId === x?.AuditorId
      );
      const nrpsLoadPlanObj = {
        PlanId: JOB_ID,
        WeekNo: WEEK_NO,
        CountryId: countryId,
        PlanStatus: "NRPS_UPLOAD",
        VendorId: authContext?.CompanyID,
        StudyDetails: [
          {
            StartDate: moment(x.StartDate).format("MM/DD/YYYY"),
            EndDate: moment(x.EndDate).format("MM/DD/YYYY"),
            StoreDetails: [
              {
                StoreId: x.StoreId,
                CollType: x.collType ? x.collType : "",
                LimitTime: x.limitTime ? x.limitTime : "",
                CollectionDays: x.collectionDays ? x.collectionDays : "",
                EstimatedEans: x.estimatedEans ? x.estimatedEans : "",
                Notes: x.noteCollection ? x.noteCollection : "", //NoteCollesction                
                VisitDate: moment(x.Duedate).format("YYYY-MM-DD"),
                LimitDate: moment(x.limitDate).format("YYYY-MM-DD"),
              },
            ],
          },
        ],
      };
      // var loadPlanObj = {
      //   StoreId: x.StoreId,
      //   StoreName: x.StoreName,
      //   Frequency: x.Frequency,
      //   Period: x.Period,
      //   AuditorId: x.AuditorId,
      //   collType: x.collType ? x.collType : "",
      //   limitTime: x.limitTime ? x.limitTime : "",
      //   collectionDays: x.collectionDays ? x.collectionDays : "",
      //   estimatedEans: x.estimatedEans ? x.estimatedEans : "",
      //   noteCollection: x.noteCollection ? x.noteCollection : "",
      //   AuditorUniqueID: auditor != undefined ? auditor.AuditorUniqueId : "",
      //   SecondaryAuditors: x.SECONDARY_RESOURCE_IDS.join("|"),
      //   StartDate: moment(x.StartDate).format("MM/DD/YYYY"),
      //   EndDate: moment(x.EndDate).format("MM/DD/YYYY"),
      //   StartTime: x.StartTime,
      //   EndTime: x.EndTime,
      //   DueDate: moment(x.DueDate).format("MM/DD/YYYY"),
      //   Status: x.Status,
      //   remarks: x.remarks,
      //   store_address: x.store_address,
      //   Outbound_Status_FromFMS:
      //     x.JobActionStatus == "Replan" ? "REASSIGNED" : "",
      // };
      // console.log("nrpsLoadPlanObj", nrpsLoadPlanObj);
      // if (action === "SUBMIT") {
      //   storeList.push(loadPlanObj);
      // } else if (action === "NRPSSUBMIT") {
      //   storeList.push(nrpsLoadPlanObj);
      // } else if (
      //   x.edited !== undefined &&
      //   x.edited.length > 0 &&
      //   action === "SAVE"
      // ) {
      //   storeList.push(loadPlanObj);
      // }
      if (action === "NRPSSUBMIT") {
        storeList.push(nrpsLoadPlanObj);
      }
    });
    return { NRPSLoadPlan: storeList };
  };

  useEffect(() => {
    if (serverColumns?.length > 0) {
      const currentDataColumns = serverColumns.find(
        (c) => c?.Grid_Name === GRID_NAME
      );
      try {
        const currentStateColumns = JSON.parse(currentDataColumns?.Json_object);
        if (currentStateColumns.every((column) => column.field)) {
          setStateColumns(currentStateColumns);
        }
      } catch (e) {
        console.log("error in state columns", currentDataColumns?.Json_object);
        return;
      }
    }
  }, [serverColumns]);

  useEffect(() => {
    if (data) {
      setResult({
        data: data?.data.map((d) => {
          columns.forEach((col) => {
            const filterType = col.filter;
            if (filterType) {
              const field = col.field;
              if (filterType === "numeric") {
                const dataValue = d[field];
                if (!isNaN(dataValue)) {
                  // d[field] = parseInt(dataValue) || null;
                  d[field] = dataValue || null;
                }
              }

              if (filterType === "date") {
                const dateValue = new Date(d[field]);
                if (dateValue instanceof Date && !isNaN(dateValue.getTime())) {
                  d[field] = dateValue || null;
                } else if (
                  typeof d[field] === "string" &&
                  d[field].toLowerCase() === "Invalid date".toLowerCase()
                ) {
                  d[field] = null;
                }
              }
            }
          });
          return d;
        }),
        ...data,
      });
    }
  }, [data]);

  useEffect(() => {
    if (result?.data?.length) {
      setGridData(getGridData);
      SAVEDGRIDDATA = [getGridData];
      setFilter(null);
      setSelectedState({});
    }
  }, [jobAction]);

  useEffect(() => {
    if (gridData.data.length) {
      setGridData(getSelectedGridData);
    }
  }, [selectedState]);

  useEffect(() => {
    window.addEventListener('paste', handlePaste);
    window.addEventListener('copy', handleCopy);

    return () => {
      window.removeEventListener('paste', handlePaste);
      window.removeEventListener('copy', handleCopy);
    };
  }, [selectedState, position, stateColumns]);


  useEffect(() => {
    if (result.data.length) {
      setGridData(getGridData);
      SAVEDGRIDDATA.push(getGridData);
    }
  }, [result]);

  console.log(previousgrid?.data,"previousgrid")


  useEffect(() => {
    console.log(result,"resultresult")
    if (result.data.length) {
      setGridData(getGridData);
      SAVEDGRIDDATA.push(getGridData);
      setPreviousGrid(getGridData)

    }
  }, [selectedRadio]);

  console.log(gridData,SAVEDGRIDDATA,"SAVEDGRIDDATASAVSAVEDGRIDDATAEDGRIDDATA")

  const lettersOnlyCheck = (stringData) => {
    const regEx = /^[A-Za-z]+$/;
    if (stringData.match(regEx) || stringData === "") {
      return true;
    }
    return false;
  }

  const handleCopy = (e) => {
    const visiableColList = stateColumns.filter((col) => col.show === true);
    let copyContent = '';
    const selectedStateKeys = Object.keys(selectedState);
    gridData.data.forEach((row) => {
      if (selectedStateKeys.includes(row.customID)) {
        const selectedCells = selectedState?.[row.customID].length;
        selectedState?.[row.customID]?.forEach((cellIndex, index) => {
          let selectedGrid = visiableColList[cellIndex].field;
          copyContent += `${row[selectedGrid]}`;
          if (index < (selectedCells - 1)) {
            copyContent += '\t';
          }
        });
        copyContent += '\r\n';
      }
    });
    e.clipboardData.setData('text/plain', copyContent.trim());
    e.preventDefault();
  }

  const handlePaste = (e) => {
    if (e.target.tagName && e.target.tagName.match(/(input|textarea)/i)) {
      // Do not handle past when an input element is currently focused
      return;
    }

    // Get clipboard data as text
    const data = e.clipboardData.getData("text");

    // Simplified parsing of the TSV data with hard-coded columns
    const clipboardData = data.trim().split("\r\n");
    let count = 0;
    let invalidDataCount = 0;
    let invalidNumericOnlyCount = 0;
    let invalidAlphabelOnlyCount = 0;
    let dataUpdatedCount = 0;
    const selectedStateKeys = Object.keys(selectedState);
    const selectedFirstRowKeyIndex = position?.startRowIndex;
    const selectedLastItemKeyIndex = selectedFirstRowKeyIndex + clipboardData.length - 1;
    const visiableColList = stateColumns.filter((col) => col.show === true);
    let storeIds = [];
    let storeIdData = [];

    if (clipboardData.length && selectedStateKeys.length) {
      let temp = [...gridData.data];
      temp.forEach((item, gridRowIndex) => {
        item["selected"] = selectedState[idGetter(item)];
        if (
          (selectedFirstRowKeyIndex <= gridRowIndex &&
            gridRowIndex <= selectedLastItemKeyIndex) ||
          selectedStateKeys.includes(item?.customID)
        ) {
          const colIndexArr = selectedStateKeys.includes(item?.customID) ?
            selectedState?.[item.customID] :
            Array.from(
              { length: position?.endColIndex - position?.startColIndex + 1 },
              (value, key) => position?.startColIndex + key
            )

          let colCount = 0;
          colIndexArr.forEach((colIndex, colIndexKey) => {
            let selectedCell = visiableColList[colIndex].field;
            if (visiableColList[colIndex].editable) {
              const clipboardRowData = clipboardData[count].trim().split("\t");
              if (dependentDataFields.includes(selectedCell)) {
                const otherdependentDataField = dependentDataFields.filter(
                  (field) => field !== selectedCell
                )[0];
                if (
                  (
                    (
                      visiableColList[colIndex].field === 'AuditorId' ||
                      visiableColList[colIndex].editor === 'numeric'
                    ) &&
                    !isNaN(clipboardRowData[colCount])
                  ) ||
                  typeof (visiableColList[colIndex].editor) === 'undefined'
                ) {
                  const curPrimaryAud = auditorDetails?.find(
                    (aud) => aud?.[(selectedCell === 'PRIMARY_RESOURCE_NAME') ? 'AuditorName' : selectedCell].trim() === clipboardRowData[colCount]
                  );

                  if (curPrimaryAud &&
                    (
                      (selectedCell === 'AuditorId' && !item?.SECONDARY_RESOURCE_IDS.includes(clipboardRowData[colCount])) ||
                      (selectedCell === 'PRIMARY_RESOURCE_NAME' && !item?.SECONDARY_RESOURCE_IDS.includes(curPrimaryAud?.AuditorId))
                    )
                  ) {
                    item[selectedCell] = clipboardRowData[colCount];
                    item[otherdependentDataField] = curPrimaryAud?.[(otherdependentDataField === 'PRIMARY_RESOURCE_NAME') ? 'AuditorName' : otherdependentDataField] ?? '';
                    item['LastUpdBy'] = authContext?.SpocMail;

                    dataUpdatedCount++;
                    if (Array.isArray(storeIdData[item.StoreId])) {
                      storeIdData[item.StoreId].push(
                        {
                          field: selectedCell,
                          fieldData: clipboardRowData[colCount]
                        },
                        {
                          field: otherdependentDataField,
                          fieldData: curPrimaryAud?.[(otherdependentDataField === 'PRIMARY_RESOURCE_NAME') ? 'AuditorName' : otherdependentDataField] ?? ''
                        }
                      )
                    } else {
                      storeIdData[item.StoreId] = [
                        {
                          field: selectedCell,
                          fieldData: clipboardRowData[colCount]
                        },
                        {
                          field: otherdependentDataField,
                          fieldData: curPrimaryAud?.[(otherdependentDataField === 'PRIMARY_RESOURCE_NAME') ? 'AuditorName' : otherdependentDataField] ?? ''
                        }
                      ];
                    }

                    if (Array.isArray(storeIds[item.StoreId])) {
                      if (!storeIds[item.StoreId].includes(item.customID)) {
                        storeIds[item.StoreId].push(item.customID);
                      }
                      storeIdData[item.StoreId].push({
                        field: selectedCell,
                        fieldData: clipboardRowData[colCount]
                      },
                        {
                          field: otherdependentDataField,
                          fieldData: curPrimaryAud?.[(otherdependentDataField === 'PRIMARY_RESOURCE_NAME') ? 'AuditorName' : otherdependentDataField] ?? ''
                        })
                    } else {
                      storeIds[item.StoreId] = [item.customID];
                      storeIdData[item.StoreId] = [
                        {
                          field: selectedCell,
                          fieldData: clipboardRowData[colCount]
                        },
                        {
                          field: otherdependentDataField,
                          fieldData: curPrimaryAud?.[(otherdependentDataField === 'PRIMARY_RESOURCE_NAME') ? 'AuditorName' : otherdependentDataField] ?? ''
                        }
                      ];
                    }

                    if (Array.isArray(item["edited"])) {
                      item["edited"].push(selectedCell);
                      item["edited"].push(otherdependentDataField);
                    } else item["edited"] = [selectedCell, otherdependentDataField];
                  } else {
                    invalidDataCount++;
                  }

                  if (clipboardRowData.length > 1) {
                    colCount++;
                  }

                  if (colIndexKey === (colIndexArr.length - 1) && clipboardData.length > 1) {
                    count++;
                  }
                } else if (
                  (
                    visiableColList[colIndex].field === 'AuditorId' ||
                    visiableColList[colIndex].editor === 'numeric'
                  ) &&
                  isNaN(clipboardRowData[colCount])
                ) {
                  invalidNumericOnlyCount++;
                }
              } else if (dependentDataFieldsSecondary.includes(selectedCell)) {
                const otherdependentDataField = dependentDataFieldsSecondary.filter(
                  (field) => field !== selectedCell
                )[0];
                const auditorArr = clipboardRowData[colCount].split(',');
                const auditorArrOtherField = [];
                if (auditorArr.length) {
                  auditorArr.forEach((audtr) => {
                    const curPrimaryAud = auditorDetails?.find(
                      (aud) => aud?.[(selectedCell === 'SECONDARY_RESOURCE_NAME') ? 'AuditorName' : 'AuditorId'].trim() === audtr
                    );

                    if (curPrimaryAud) {
                      auditorArrOtherField.push(curPrimaryAud?.[(otherdependentDataField === 'SECONDARY_RESOURCE_NAME') ? 'AuditorName' : 'AuditorId']);
                    }
                  })
                  if (
                    (
                      (selectedCell === 'SECONDARY_RESOURCE_IDS' && !auditorArr.includes(item?.AuditorId)) ||
                      (selectedCell === 'SECONDARY_RESOURCE_NAME' && !auditorArrOtherField.includes(item?.AuditorId))
                    )
                  ) {

                    item[selectedCell] = auditorArr;
                    item[otherdependentDataField] = auditorArrOtherField;
                    item['LastUpdBy'] = authContext?.SpocMail;

                    dataUpdatedCount++;
                    if (Array.isArray(storeIdData[item.StoreId])) {
                      storeIdData[item.StoreId].push(
                        {
                          field: selectedCell,
                          fieldData: auditorArr
                        },
                        {
                          field: otherdependentDataField,
                          fieldData: auditorArrOtherField
                        }
                      )
                    } else {
                      storeIdData[item.StoreId] = [
                        {
                          field: selectedCell,
                          fieldData: auditorArr
                        },
                        {
                          field: otherdependentDataField,
                          fieldData: auditorArrOtherField
                        }
                      ];
                    }

                    if (Array.isArray(storeIds[item.StoreId])) {
                      if (!storeIds[item.StoreId].includes(item.customID)) {
                        storeIds[item.StoreId].push(item.customID);
                      }
                      storeIdData[item.StoreId].push({
                        field: selectedCell,
                        fieldData: auditorArr
                      },
                        {
                          field: otherdependentDataField,
                          fieldData: auditorArrOtherField
                        })
                    } else {
                      storeIds[item.StoreId] = [item.customID];
                      storeIdData[item.StoreId] = [
                        {
                          field: selectedCell,
                          fieldData: auditorArr
                        },
                        {
                          field: otherdependentDataField,
                          fieldData: auditorArrOtherField
                        }
                      ];
                    }

                    if (Array.isArray(item["edited"])) {
                      item["edited"].push(selectedCell);
                      item["edited"].push(otherdependentDataField);
                    } else item["edited"] = [selectedCell, otherdependentDataField];
                  }
                }

                if (clipboardRowData.length > 1) {
                  colCount++;
                }

                if (colIndexKey === (colIndexArr.length - 1) && clipboardData.length > 1) {
                  count++;
                }
              } else {
                if (
                  (
                    visiableColList[colIndex].editor === 'numeric' &&
                    !isNaN(clipboardRowData[colCount])
                  ) ||
                  (
                    (
                      visiableColList[colIndex].field === 'StartTime' ||
                      visiableColList[colIndex].field === 'EndTime' ||
                      visiableColList[colIndex].field === 'limitTime'
                    ) &&
                    validateTimeCheck(clipboardRowData[colCount])
                  ) ||
                  (
                    (
                      visiableColList[colIndex].field === 'limitDate' ||
                      visiableColList[colIndex].field === 'StartDate' ||
                      visiableColList[colIndex].field === 'EndDate'
                    ) &&
                    moment(clipboardRowData[colCount], 'MM/DD/YYYY', true).isValid()
                  ) ||
                  (
                    visiableColList[colIndex].editor === 'text' &&
                    lettersOnlyCheck(clipboardRowData[colCount])
                  )
                ) {
                  item[selectedCell] = clipboardRowData[colCount];
                  item['LastUpdBy'] = authContext?.SpocMail;

                  if (clipboardRowData.length > 1) {
                    colCount++;
                  }

                  if (colIndexKey === (colIndexArr.length - 1) && clipboardData.length > 1) {
                    count++;
                  }

                  dataUpdatedCount++;
                  if (Array.isArray(storeIdData[item.StoreId])) {
                    storeIdData[item.StoreId].push(
                      {
                        field: selectedCell,
                        fieldData: clipboardRowData[colCount]
                      }
                    )
                  } else {
                    storeIdData[item.StoreId] = [
                      {
                        field: selectedCell,
                        fieldData: clipboardRowData[colCount]
                      }
                    ];
                  }

                  if (Array.isArray(item["edited"]))
                    item["edited"].push(selectedCell);
                  else item["edited"] = [selectedCell];
                } else if (
                  visiableColList[colIndex].editor === 'numeric' &&
                  isNaN(clipboardRowData[colCount])
                ) {
                  invalidNumericOnlyCount++;
                } else if (
                  visiableColList[colIndex].editor === 'text' &&
                  !lettersOnlyCheck(clipboardRowData[colCount])
                ) {
                  invalidAlphabelOnlyCount++;
                }
              }
            } else {
              showToast("error", t("Field is non-editable"));
            }
          })
        }
        return item;
      });

      const originalData = filter ? { data: filterBy(SAVEDGRIDDATA[0].data, filter), total: gridData.total } : SAVEDGRIDDATA[0];

      temp.map((item, gridRowIndex) => {
        item["selected"] = selectedState[idGetter(item)];
        if (
          (selectedFirstRowKeyIndex > gridRowIndex ||
            gridRowIndex > selectedLastItemKeyIndex) &&
          !selectedStateKeys.includes(item?.customID)
        ) {
          if (Array.isArray(storeIds[item.StoreId]) && !storeIds[item.StoreId].includes(item.customID)) {
            const key = 'field';
            const arrayUniqueByKey = [...new Map(storeIdData[item.StoreId].map(item =>
              [item[key], item])).values()];
            arrayUniqueByKey?.forEach((objData) => {
              item[objData.field] = objData.fieldData;
              item['LastUpdBy'] = authContext?.SpocMail;
              if (Array.isArray(item["edited"])) {
                item["edited"].push(objData.field);
              } else item["edited"] = [objData.field];
            });
          }
        }
        return item;
      });

      const newDataWithEditedFieldUpdated = checkIfFieldIsEditedWithNewData(
        temp,
        originalData
      );
      const newvalue = {
        data: newDataWithEditedFieldUpdated,
        total: gridData.total,
      };
      if (filter) {
        const lastSelectedItem = JSON.parse(JSON.stringify(SAVEDGRIDDATA[SAVEDGRIDDATA.length - 1].data));
        lastSelectedItem.forEach((val, key) => {
          if (Array.isArray(storeIdData[val.StoreId])) {
            const key = 'field';
            const arrayUniqueByKey = [...new Map(storeIdData[val.StoreId].map(item =>
              [item[key], item])).values()];
            arrayUniqueByKey?.forEach((objData) => {
              val[objData.field] = objData.fieldData;
              if (Array.isArray(val["edited"])) {
                val["edited"].push(objData.field);
              } else val["edited"] = [objData.field];
            });
            val['LastUpdBy'] = authContext?.SpocMail;
          }
        });
        const newTempDataWithEditedFieldUpdated = checkIfFieldIsEditedWithNewData(
          lastSelectedItem,
          SAVEDGRIDDATA[0]
        );
        let newTempValue = {
          data: newTempDataWithEditedFieldUpdated,
          total: newTempDataWithEditedFieldUpdated.length,
        };
        SAVEDGRIDDATA.push(newTempValue);
      } else {
        dataUpdatedCount && SAVEDGRIDDATA.push(newvalue);
      }
      setGridData(newvalue);
    }
  };

  const CreatePager = useCallback((props) => (
    <MyPager
      {...props}
      onSaveViewClick={handleSaveViewClick}
      onDefaultViewClick={handleDefaultViewClick}
    >
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "flex-end"
        }}
      />
    </MyPager>
  ), [stateColumns]);


  const handleRadioChange = (e) => {
    const value = e?.value;
    setSelectedRadio(e.value); // Update selected radio value
    setStateColumns(initialBlockIdColumnsState);
    if (value === "blockId") {
     
      if (Array?.isArray(fetchedData?.data)) {
        const uniqueBlockIdData = Object.values(
          fetchedData?.data?.reduce((acc, curr) => {
            if (!acc[curr.blockId]) {
              acc[curr.blockId] = curr; // Keep the first occurrence of each blockId
            }
            return acc;
          }, {})
        ).sort((a, b) => a.blockId - b.blockId); // Sort by blockId
       

        setGridData(
          {
            data: uniqueBlockIdData,
            total: uniqueBlockIdData?.length
          });
      } else {
        console.error("fetchedData is not an array");
      }
      // setGridData(uniqueBlockIdData)
      

    } else if (value === "storeId") {
      // Reset to initial columns
      setStateColumns(initialColumnsState);
      setGridData(
        {
          data:  fetchedData?.data,
          total:  fetchedData?.data?.length
        });
    }
  };

  return (
    <React.Fragment>
      {showLoader && <LoadMask {...showLoader} />}
      {messageProps && <MessageBox {...messageProps} />}
      {uploadWindow && (
        <UploadWindow
          selectedDataUpload={{
            ...workOrderDetail,
            WorkOrderId: workOrderDetail?.JobId,
            BlockId:selectedRadio === "storeId" ? false : true
          }}
          setUploadWindow={setUploadWindow}
          setShouldRefresh={setShouldRefresh}
        />
      )}
      {upcWindow && (
        <UpcWindow setUpcWindow={setUpcWindow} upcWindow={upcWindow} />
      )}
      <div className="catalog" style={{ minHeight: '60%', flexGrow: 1 }}>
        <div>
          <Header activeMenu="Work Order Id" wrapperStyle={{ gap: "0px", fontSize: "16px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  backgroundColor: "#ffffff",
                  padding: '5px'
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "65px",
                    position: "relative",
                    top: "5px"
                  }}
                >
                  {JOB_ID}
                </div>
                <div>
                  <ViewMore workOrderDetail={workOrderDetail} />
                </div>
              </div>
              <Button
                className='k-secondary'
                style={{
                  marginRight: '17px',
                }}
                icon="k-icon k-i-arrow-left left-arrow k-icon-lg"
                onClick={() => history.push("/work_orders")}>
                Back
              </Button>
            </div>
          </Header>
          <div
            style={{
              margin: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div style={{display:"flex"}}>
              <RadioGroup
                data={radioData}
                defaultValue={jobAction}
                onChange={(e) => setJobAction(e?.value)}
                layout={"horizontal"}
               // disabled={roleWorkOrderPage?.screensInfo?.screenId == screen ? (roleWorkOrderPage?.createFlag == 'N' ? true : false) : true}

              />
              <div style={{display:"flex",marginLeft:"3rem"}}>
              
              {hasNonZeroBlockId && (
                  <div style={{display:"flex"}}>
                    <div style={{marginTop: "0.3rem", marginRight: "2rem", fontWeight: 600}}> Assignment Mode : </div>
                    <RadioGroup
                    value={selectedRadio}
                    onChange={handleRadioChange}
                    layout={"horizontal"}
                    data={[
                        { label: "Store ID", value: "storeId" },
                        { label: "Block ID", value: "blockId" },
                    ]}
                    />

                  </div>
              )}
              </div>
            </div>
            <div
              style={{
                flex: "1",
                textAlign: "end",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: 16,
              }}
            >
              {showGridTools && upcRedistribution && <UpcBtn workOrderDetail={workOrderDetail} />}
              {jobAction === "plan" && (
                <Tooltip position="bottom" anchorElement="target">
                  {
                    roleWorkOrderPage?.screensInfo?.screenId == screen ? (roleWorkOrderPage?.createFlag == 'N' ? <></> : <span
                      title="Pre fill"
                      style={{ cursor: "pointer" }}
                      className="k-icon k-i-copy planHeader-iconSize"
                      onClick={() => {
                        dispatch({
                          type: actionTypes.SET_WORKORDER_DETAIL,
                          payload: {
                            dataItem: workOrderDetail,
                          },
                        })
                        dispatch({
                          type: actionTypes.SET_ACTION_WORKORDER_DETAIL,
                          payload: {
                            ...SAVEDGRIDDATA[0],
                          },
                        })
                        history.push("/work_orders/prefill", { workOrderDetail })
                      }
                      }
                    />) : <></>


                  }
                </Tooltip>
              )}
              {jobAction === "replan" && (
                <Tooltip position="bottom" anchorElement="target">
                  {
                    roleWorkOrderPage?.screensInfo?.screenId == screen ? (roleWorkOrderPage?.createFlag == 'N' ? <></> :
                      <span
                        title="Validate Window"
                        style={{ cursor: "pointer" }}
                        className="k-icon k-i-check-circle planHeader-iconSize"
                        onClick={() =>
                          history.push("/work_orders/replanValidate", {
                            workOrderDetail,
                          })
                        }
                      />) : <></>}
                </Tooltip>
              )}
              <Tooltip position="bottom" anchorElement="target">
                <span
                  title="Refresh"
                  onClick={() => {
                    fetchStoreList();
                    setFilter(null);
                  }}
                  className="k-icon k-i-refresh planHeader-iconSize"
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
              {showGridTools && (
                <>
                  <Tooltip position="bottom" anchorElement="target">
                    <span
                      title="Undo"
                      onClick={undoGridEdit}
                      style={{ cursor: "pointer" }}
                      className={
                        SAVEDGRIDDATA.length > 1
                          ? "k-icon k-i-undo planHeader-iconSize"
                          : "k-icon k-i-undo planHeader-iconSize k-icon-disabled"
                      }
                    />
                  </Tooltip>
                  <Tooltip position="bottom" anchorElement="target">

                    {
                      roleWorkOrderPage?.screensInfo?.screenId == screen ? (roleWorkOrderPage?.createFlag == 'N' ? <></> :
                        <span
                          title="Save"
                          style={{ cursor: "pointer" }}
                          onClick={handleOnSaveButtonClick}
                          className="k-icon k-i-save planHeader-iconSize"
                        />) : <></>}
                  </Tooltip>
                  {
                    nrpsReport && <Tooltip position="bottom" anchorElement="target">

                      {
                        roleWorkOrderPage?.screensInfo?.screenId == screen ? (roleWorkOrderPage?.createFlag == 'N' ? <></> :
                          <span
                            title="Submit"
                            style={{ cursor: "pointer" }}
                            onClick={handleOnSubmitButtonClick}
                            className="k-icon k-i-bac planHeader-iconSize"
                          />) :
                          <></>
                      }
                    </Tooltip>
                  }
                  <Tooltip position="bottom" anchorElement="target">
                    {
                      roleWorkOrderPage?.screensInfo?.screenId == screen ? (roleWorkOrderPage?.createFlag == 'N' ? <></> :
                        <span
                          title="Upload Store List"
                          onClick={() => handleUploadWindow()}
                          style={{ cursor: "pointer" }}
                          className="k-icon k-i-upload planHeader-iconSize"
                        />) : <></>}
                  </Tooltip>
                </>
              )}

              <Tooltip position="bottom" anchorElement="target">
                <span
                  title="Download Store List"
                  onClick={excelExport}
                  style={{ cursor: "pointer" }}
                  className="k-icon k-i-download planHeader-iconSize"
                />
              </Tooltip>
              <Tooltip position="bottom" anchorElement="target">
                <a
                  href={process.env.PUBLIC_URL + "/assets/WorkorderGuidlines.pdf"}
                  target={"_blank"}
                  style={{ color: "#000" }}
                  className="k-icon k-i-question planHeader-iconSize"
                  title="Help"
                  rel="noreferrer"
                > </a>
              </Tooltip>
              {showGridTools && (
                <Button
                  className="k-button k-primary"
                  onClick={handleOnStartCollectionBtnClick}
                  disabled={roleWorkOrderPage?.screensInfo?.screenId == screen ? (roleWorkOrderPage?.createFlag == 'N' ? true : false) : true}
                >
                  Start Collection
                </Button>
              )}
            </div>
          </div>
        </div>
        <div style={{ padding: '10px', display: "flex", flexGrow: 1 }}>
          <Grid
            resizable={true}
            data={gridData?.data?.slice(dataState?.skip, dataState?.skip + pageSize)}
            // data=gridData.data
            {...dataState}
            style={{
              minHeight: '100%',
              position: 'sticky'
            }}
            rowHeight={50}
            pageSize={pageSize}
            scrollable={'virtual'}
            onPageChange={onPageChange}
            onDataStateChange={dataStateChange}
            sortable={true}
            sort={sort}
            dataItemKey={DATA_ITEM_KEY}
            selectedField={"selected"}
            selectable={{
              enabled: true,
              drag: true,
              cell: true,
              mode: "multiple",
            }}
            total={Number(gridData?.total) ?? 0}
            onItemChange={itemChange}
            cellRender={customCellRender}
            rowRender={customRowRender}
            editField={EDIT_FIELD}
            navigatable={true}
            onNavigationAction={onNavigationAction}
            onKeyDown={onKeyDownHandle}
            onSelectionChange={onSelectionChange}
            pageable={{
              pageSizes: true,
            }}
            onSortChange={(e) => {
              setGridData((gridData) => {
                const { data, total } = gridData;
                return {
                  data: orderBy(data || [], e.sort),
                  total,
                };
              });
              setSort(e.sort);
            }}
            filter={filter}
            onFilterChange={(e) => {
              const lastSavedItem = SAVEDGRIDDATA[SAVEDGRIDDATA.length - 1];
              if (e.filter) {
                const { data } = lastSavedItem;
                const filterData = filterBy(data || [], e.filter);
                setGridData({
                  data: filterData,
                  total: filterData.length,
                });
              } else {
                setGridData(lastSavedItem);
              }
              setFilter(e.filter);
            }}
            pager={CreatePager}
            reorderable
            onColumnReorder={({ columns }) => {
              const columnOrder = columns.reduce((columnsObj, column) => {
                columnsObj[column.field] = column.orderIndex;
                return columnsObj;
              }, {});

              const newColumns = [];

              stateColumns.forEach((column) => {
                newColumns[columnOrder[column.field]] = { ...column, orderIndex: columnOrder[column.field] };
              })
              setStateColumns(newColumns);
            }}
          >
            {stateColumns.map((column, idx) => {
              const menu = columnMenuConfig[column?.columnMenu];
              return (
                (column.show || (jobAction === 'collected' && column.field === 'recollect' && COLLECTION_TYPE?.toLowerCase() === 'ecollection')) && (
                  <GridColumn
                    key={idx}
                    field={column.field}
                    title={column.title}
                    width={column.width}
                    editable={column.editable}
                    editor={column.editor}
                    onClick={() => history.push(column?.path)}
                    //cell={GridCell}
                    format={column?.format}
                    filter={column?.filter}
                    orderIndex={column?.orderIndex}
                    {...(menu && {
                      columnMenu: (props) => {
                        return menu?.({
                          ...props,
                          columns: stateColumns,
                          onColumnsSubmit,
                          data: data?.data,
                        });
                      },
                    })}
                    headerClassName={
                      isColumnActive(column.field, { ...dataState, filter })
                        ? "activeFiltered"
                        : ""
                    }
                  />
                )
              );
            })}
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ActionWorkOrder);
